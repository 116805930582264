(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

var App = function App() {
  _classCallCheck(this, App);

  console.info('ES6 Modules work!');
};

var _default = App;
exports.default = _default;

},{}],2:[function(require,module,exports){
"use strict";

var _app = _interopRequireDefault(require("./app.js"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var app = new _app.default();
$(document).on('click', function (event) {
  // Проверяем, произошел ли клик вне блока меню
  if (!$(event.target).closest('.wrapper-mega-menu').length && !$(event.target).closest('.catalog__menu-btn').length) {
    // Закрываем меню
    $('.catalog__menu-btn').removeClass('open');
    $('.wrapper-mega-menu').removeClass('open');
    /*  $('body').removeClass('overflow_hidden'); */

    /* $('').fadeOut(); */

    /*   $('.departments__item.item-hover').removeClass('item-hover'); */
  }
});
$('.catalog__menu-btn').on('click', function (e) {
  e.stopPropagation(); // Предотвращаем всплытие события до документа

  $('.catalog__menu-btn').toggleClass('open');
  $('.wrapper-mega-menu').toggleClass('open');
  $('body').toggleClass('overflow_hidden');
  /*  if ($('.departments__item').has('.main-hover-menu').length > 0) {
       $('.departments__item').has('.main-hover-menu').first().addClass('item-hover');
   } else {
       $('.departments__item').hasClass('open').first().removeClass('item-hover');
   } */

  if ($('body').hasClass('overflow_hidden')) {
    $('.popup-overlay').fadeIn();
  } else {
    $('.popup-overlay').fadeOut();
  }
});
/* $('.wrapper-mega-menu').on('mouseleave', function() {
    // Закрываем меню при уходе курсора с блока меню
    $('.catalog__menu-btn').removeClass('open');
    $('.wrapper-mega-menu').removeClass('open');
    $('body').removeClass('overflow_hidden');
    $('.popup-overlay').fadeOut();
    // Убираем класс item-hover, если он был добавлен
    $('.departments__item.item-hover').removeClass('item-hover');
});
 */

jQuery(document).ready(function ($) {
  $('.rating-list input').on('change', function () {
    $(this).closest('.rating-list').find('input').prev().removeClass('selected');
    $(this).prevAll('input').addBack().prev('input').addClass('selected');
  });
});
/* $(document).ready(function() {
    $('.one__product-visible-row').hover(
        function() {
            $(this).closest('.block-item').css({
                'box-shadow': '0px 4px 19px 0px rgba(0, 0, 0, 0.10)',
                'transition': 'box-shadow 0.3s linear',
                'cursor': 'pointer',
                'background': '#FFF',   
            });
        },
        function() {
            $(this).closest('.block-item').css({
                'box-shadow': 'none',
                'transition': 'box-shadow 0.3s linear',
                'cursor': 'pointer',
                'background': '#FFF',
                
            });
        }
    );
}); */

/* $(document).ready(function() {
    $('.one__product-visible-row').hover(
        function() {
            $(this).closest('.one__product-main-info').css({
                'box-shadow': '0px 4px 19px 0px rgba(0, 0, 0, 0.10)',
                'transition': 'box-shadow 0.3s linear',
                'cursor': 'pointer',
                'background': '#FFF',   
            });
        },
        function() {
            $(this).closest('.one__product-main-info').css({
                'box-shadow': 'none',
                'transition': 'box-shadow 0.3s linear',
                'cursor': 'pointer',
                'background': '#FFF',
                
            });
        }
    );
}); */

jQuery(document).ready(function ($) {
  $('.accordion-header').click(function () {
    $(this).toggleClass('active').next('.accordion-content').slideToggle();
  });
});
jQuery(document).ready(function ($) {
  $("#carousel-reviews-main").owlCarousel({
    items: 1,
    // Количество отображаемых элементов
    loop: true,
    // Зациклить карусель
    nav: true,
    // Навигация (стрелки вперед/назад)
    dots: true,
    // Индикаторы точек
    // Другие параметры по вашему выбору
    navText: ['<svg xmlns="http://www.w3.org/2000/svg" width="65" height="47" viewBox="0 0 65 47" fill="none"><rect width="65" height="47" fill="#12202B"/><path d="M29.8 33L31.34 31.425L25.18 25.125L43 25.125L43 22.875L25.18 22.875L31.34 16.575L29.8 15L21 24L29.8 33Z" fill="#E3E3E3"/></svg>', '<svg xmlns="http://www.w3.org/2000/svg" width="65" height="47" viewBox="0 0 65 47" fill="none"><rect x="65" y="47" width="65" height="47" transform="rotate(180 65 47)" fill="#12202B"/><path d="M34.2 15L32.66 16.575L38.82 22.875H21V25.125H38.82L32.66 31.425L34.2 33L43 24L34.2 15Z" fill="#E3E3E3"/></svg>']
  });
});
jQuery(document).ready(function ($) {
  $('.burger-icon').click(function () {
    $('.menu__body').toggleClass('active-menu');
    $('.burger-icon').toggleClass('burger-btn--open');
    $('body').toggleClass('overflow_hidden');

    if ($('body').hasClass('overflow_hidden')) {
      // Запрещаем прокрутку страницы
      $('body').css('overflow', 'hidden'); // Добавляем класс для предотвращения дергания меню

      $('.menu__body').addClass('fixed-menu');
    } else {
      // Разрешаем прокрутку страницы
      $('body').css('overflow', ''); // Удаляем класс, предотвращающий дергание меню

      $('.menu__body').removeClass('fixed-menu');
    }

    if ($('.popup-overlay').length > 0) {
      if ($('body').hasClass('overflow_hidden')) {
        // Показываем overlay, если он существует и меню открыто
        $('.popup-overlay').fadeIn('slow');
      } else {
        // Прячем overlay, если он существует и меню закрыто
        $('.popup-overlay').fadeOut('slow');
      }
    }
  });
});
jQuery(document).ready(function ($) {
  $('.category__link').click(function () {
    $('.menu__content').toggleClass('show');
    $('.mobile-catalog').toggleClass('active');
  });
});
jQuery(document).ready(function ($) {
  $('.dropdown__body-menu').on('click', function (event) {
    //event.preventDefault(); 
    $('.mobile-catalog').toggleClass('active-second');
    $(this).closest('.dropdown__body-menu').find('.dropdown-inner').toggleClass('active');
  });
});
jQuery(document).ready(function ($) {
  $('.dropdown-inner.active .back-catalog').on('click', function (event) {
    console.log($('.mobile-catalog.active'));
    $('.mobile-catalog.active').removeClass('active-second');
  });
});
jQuery(document).ready(function ($) {
  $('.home-katalog').on('click', function (event) {
    $('.mobile-catalog').removeClass('active');
    $('.menu__content').removeClass('show');
  });
});
/* jQuery(document).ready(function ($) {
    $('.col-icon.phone').click(function() {
        $('.mob-phone-block').toggleClass('phone--open');

        $(this).toggleClass('active');

    });
}); */

/* jQuery(document).ready(function ($) {
    $('.departments__item').hover(
      function() {
        $(this).addClass('item-hover');
      },
      function() {
        $(this).removeClass('item-hover');
    
    
      }
    );
  }); */

/* Fancybox.bind('[data-fancybox="gallery"]', {
    //
  }); */

},{"./app.js":1}]},{},[2]);
